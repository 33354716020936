import {useRoute, useRouter} from "vue-router";

export function checkOrTeachBill() {
  const router = useRouter();
  const route = useRoute();

  const onCardClick = async (type: number) => {
    if (type === 1){
      await router.replace({
        name: 'CheckStudent',
        query: route.query,
      })
    }else if (type === 2){
      await router.replace({
        name: 'Courseware',
        query: route.query
      })
    }
  }


  return {
    onCardClick,
  }
}
