
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import {defineComponent} from "vue";
import {Card} from 'ant-design-vue'
import {checkOrTeachBill} from "@/views/timetable/myTimetable/CheckOrTeach/CheckOrTeach";
export default defineComponent({
  name: "CheckOrTeach",
  components: {
    LogoutIcon,
    ACard: Card,
    ACardMeta: Card.Meta,
  },
  setup(){
    const {
      onCardClick,
    } = checkOrTeachBill();
    return {
      onCardClick,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
